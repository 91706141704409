class MrAudioPlayer extends HTMLElement {

	constructor() {
		// If you define a constructor, always call super() first!
		// This is specific to CE and required by the spec.
		super();

		this.init();
	}

	init() {
		this.elements = {};
		this.elements.audio = this.querySelector( '.js-audio-player__audio' );
		this.elements.play = this.querySelector( '.js-audio-player__play' );
		this.elements.progressCurrent = this.querySelector( '.js-audio-player__progress__current' );
		this.elements.progressDuration = this.querySelector( '.js-audio-player__progress__duration' );

		this.initial = true;
		this.currentTime = this.secTommss( 0 );

		if ( 0 < this.elements.audio.readyState ) {
			this.setup();
		}

		this.bind();
	}

	bind() {
		// audio events
		if ( this.elements.audio ) {
			this.elements.audio.addEventListener( 'canplaythrough', () => {
				if ( this.initial ) {
					this.setup();
				}
			} );

			this.elements.audio.addEventListener( 'play', () => {
				this.classList.add( 'is-playing' );
			} );

			this.elements.audio.addEventListener( 'pause', () => {
				this.classList.remove( 'is-playing' );
			} );

			this.elements.audio.addEventListener( 'timeupdate', () => {
				if ( this.currentTime !== this.secTommss( this.elements.audio.currentTime ) ) {
					this.currentTime = this.secTommss( this.elements.audio.currentTime );
					this.render();
				}
			} );
		}

		// Play button
		if ( this.elements.play ) {
			this.elements.play.addEventListener( 'click', () => {
				this.togglePlayback();
			} );
		}
	}

	setup() {
		this.duration = this.secTommss( this.elements.audio.duration );

		if ( isNaN( this.elements.audio.duration ) || 0 === this.elements.audio.duration ) {
			this.tryForDuration( 5 );
		} else {
			this.render();
		}

		this.initial = false;
	}

	tryForDuration( max = 5 ) {
		let tries = 0;

		this.checkInterval = setInterval( () => {
			tries += 1;

			if ( !isNaN( this.elements.audio.duration ) && 0 < this.elements.audio.duration ) {
				this.duration = this.secTommss( this.elements.audio.duration );
				clearInterval( this.checkInterval );
			} else {
				this.render();
			}

			if ( tries === max ) {
				clearInterval( this.checkInterval );
			}
		}, 960 );
	}

	togglePlayback() {
		if ( this.elements.audio.paused ) {
			this.elements.audio.play();
		} else {
			this.elements.audio.pause();
		}
	}

	play() {
		if ( this.elements.audio.paused ) {
			this.elements.audio.play();
		}
	}

	pause() {
		if ( !this.elements.audio.paused ) {
			this.elements.audio.pause();
		}
	}

	render() {
		if ( this.elements.progressCurrent && 'undefined' !== typeof this.currentTime ) {
			this.elements.progressCurrent.setAttribute( 'data-text', this.currentTime );
		}

		if ( this.elements.progressDuration && 'undefined' !== typeof this.duration ) {
			this.elements.progressDuration.setAttribute( 'data-text', this.duration );
		}
	}

	// source: https://stackoverflow.com/a/17599273
	secTommss( sec ) {
		return new Date( sec * 1000 ).toUTCString().split( ' ' )[4].substr( 3 );
	}
}

document.addEventListener( 'readystatechange', ( event ) => {
	if ( 'complete' === event.target.readyState ) {
		customElements.define( 'mr-audio-player', MrAudioPlayer );
	}
} );
